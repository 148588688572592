.vc-list {
    table {
        margin: 0;

        th a {
            color: #fff;

            i {
                margin-left: 2px;
            }
        }
    }
}

.vc-list__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-top: 1px solid #e7e8ef;
}

.vc-list__results-count {
    color: #6c757d;
}

.vc-list__no-results {
    padding-bottom: 20px;

    & > i {
        font-size: 10rem;
        color: #ddd;
    }
}
