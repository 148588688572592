.form-group {
    color: #74788d;
}

.form-group--bordered {
    border-bottom: 1px solid #eff0f6;
    padding-bottom: 1rem;
}

span.req {
    color: $color-danger;
    font-size: 0.9rem;
    white-space: pre;
}

.input-group-prepend i {
    width: 18px;
}

.form-text-help {
    color: #6c757d;
    width: 100%;
}

//
// Inline form
//
.form-inline {
    display: block;

    label {
        flex: 0 1 22%;
        justify-content: flex-end;
        padding-right: 15px;
        text-align: right;
    }

    .form-group {
        flex: 1;
        margin-bottom: 5px;
        align-items: flex-start;
    }

    .input-group {
        flex: 1;
    }

    @include mq($until: wide) {
        label {
            flex: 0 1 30%;
        }
    }

    .form-section {
        width: calc(100% + 50px);
        margin: 0 -25px;
    }
}

//
// Checkboxes
//
.custom-control-label::before {
    top: 0.3rem;
}

.custom-control-label::after {
    top: 0.3rem;
    background: no-repeat 50% / 55% 55%;
}


.custom-select {
    appearance: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';

    &::-ms-expand {
        display: none;
    }
}

.custom-control-input {
    &:checked ~ .custom-control-label {
        &::before {
            color: $color-primary;
            background: $color-primary;
        }

        &::after {
            background: none;
            content: "\f00c";
            font-family: "Font Awesome 5 Pro";
            font-weight: 600;
            font-size: 0.60rem;
            color: $color-primary-invert;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &:not(:disabled):active ~ .custom-control-label::before {
        background-color: #fff;
    }
}

.custom-checkbox {
    .custom-control-input:checked ~ .custom-control-label {
        &::after {
            background-image: none;
        }
    }
}

//
// Froala editor
//
.fr-box.fr-basic > .fr-wrapper {
    border-bottom: none;
}

.was-validated.is-invalid .fr-box {
    & > .fr-toolbar, & > .fr-wrapper, & > .second-toolbar{
        border-color: $color-danger;
    }

    & > .fr-wrapper {
        border-bottom: 1px solid #CCCCCC;
    }
}

.was-validated.is-valid .fr-box {
    & > .fr-toolbar, & > .fr-wrapper, & > .second-toolbar{
        border-color: $color-success;
    }

    & > .fr-wrapper {
        border-bottom: 1px solid #CCCCCC;
    }
}

