.alertify, .alertify.ajs-resizable {
    .ajs-dialog {
        border-radius: 4px;
        overflow: hidden;
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    &:not(.ajs-maximized) {
        .ajs-dialog {
            max-height: 80%;
        }
    }

    .ajs-header {
        background: none;
        border-color: #eff0f6;
        font-size: 1.2rem;
        font-weight: 500;
        color: $color-primary;
        position: relative !important;
        margin: 0 !important;
    }

    .ajs-body {
        flex: 1;
        overflow: auto;
        margin: 0;
        min-height: 0;

        .ajs-content {
            position: static;
            overflow: initial !important;
            height: 100%;
            padding: 16px;

            .ajs-input {
                display: block;
                width: 100%;
                height: calc(1.5em + 0.75rem + 2px);
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #495057;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            }
        }
    }

    .ajs-footer {
        background: #fff;
        border-color: #eff0f6;
        position: static;
        margin: 0;
        flex: 0 0;
        min-height: 52px;

        .ajs-buttons .ajs-button {
            padding: .65rem 1rem;
            font-size: 1rem;
            line-height: 1.5;
            text-transform: none;
            color: #4b5174;
        }
    }

    .ajs-commands {
        margin: 12px 12px 0 0;

        button {
            padding: 15px;
        }
    }
}

