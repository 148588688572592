.vc-list__column-filter {
    // So children can get 100% height
    // @see https://stackoverflow.com/a/3542470/1110635
    height: 1px;
    min-height: 1px;
}

.vc-list__column-filter__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;

    .form-group {
        flex: 1;
        margin: 0;
    }
}
