
/* ----- Section----- */

section {
    position: relative;
}

.section__content {
    position: relative;
    margin: 0 auto;
    padding-top: 15px;
    flex: 1;
}

/* ----- Page Wrapper----- */
.container {
    max-width: 1320px;
}

.page-wrapper {
    overflow: hidden;
}

.page-container {
    background: $color-grey-light;
}

.menu-sidebar + .page-container {
    padding-left: 280px;

    .main-sidebar-condensed & {
        padding-left: 60px;
    }
}

.main-content {
    display: flex;
    min-height: 100vh;
}
