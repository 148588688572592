.vc-form-date {
    .popover-container {
        flex: 1;
    }

    // Because v-calendar puts a wrapper div around the input that breaks bootstrap's addons.
    .has-before-addon input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    // Because v-calendar puts a wrapper div around the input that breaks bootstrap's addons.
    .has-after-addon input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
