.badge {
    display: inline-block;
    padding: 0.5em 0.8em;
    font-size: 0.8rem;
    line-height: 1rem;
}

.badge-sm {
    font-size: 0.7rem;
    padding: 0.3rem 0.5rem;
    line-height: 0.8rem;
}

.badge-xl {
    font-size: 1rem;
}
